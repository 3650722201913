import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { C as FormSuccessCode } from 'constants/enums/FormSuccessCode';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useRegistrationApi } from 'api/registration';

import { Text, Title } from 'components/Atoms/Text';
import Button from 'components/Atoms/Button/Button';
import Form from 'components/Molecules/Form/Form';
import { TextInput } from 'components/Atoms/Form';
import Divider from 'components/Atoms/Divider/Divider';
import SvgReload from 'components/Atoms/SVG/Icons/SvgReload';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setVcJwtToken } from 'store/App/actions';

const Otp = () => {
	const { getCurrentFlow, isStaticSubType, isCreditSubType, isCreditCardSubType } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, addErrorMessage, addSuccessMessage } = useValidationUtils();
	const { continueInvalid, continueValid } = useTrackingUtils();
	const { goToPage } = usePageUtils();
	const { postLogin, postGenerateOtp, postVerifyOtp } = useRegistrationApi();

	const currentFlow = getCurrentFlow();

	const { isRegistration } = useParams();
	const registration = isRegistration !== undefined && isRegistration.length > 0 && isRegistration === 'registrierung';

	const location = useLocation();
	const dispatch = useDispatch();
	const storeAccessToken = useSelector((state => state.app.accessToken));
	const storeRelogin = useSelector((state => state.app.relogin));
	const storeCountryCode = useSelector((state => state.mobileNumber.countryCode));
	const storeMobileNumber = useSelector((state => state.mobileNumber.mobileNumber));
	const storeEncryptedMobileNumber = useSelector((state => state.mobileNumber.encryptedMobileNumber));

	const [otp, setOtp] = useState(null);
	const [otpError, setOtpError] = useState(null);
	const [resendCodeDisabled, setResendCodeDisabled] = useState(true);

	const resendTimeout = 10000;

	useEffect(() => {
		// Call /login if email is given as a jwt token to get the mobileNumber
		const email = location.search !== undefined ? new URLSearchParams(location.search).get('email') : null;
		if (email) {
			postLogin(
				email,
				() => {
					continueValid();
				},
				(status) => {
					if (status === 428) {
						continueValid();
						goToPage(currentFlow + C.ROUTES.EMAIL_SENT);
					} else {
						continueInvalid();
					}
				}
			);
		}

		setTimeout(() => {
			setResendCodeDisabled(false);
		}, resendTimeout);
	}, []);

	const clearErrors = () => {
		setOtpError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidOtp = true;

		if (isEmpty(otp)) {
			setOtpError(m('validation.error.requiredNamed', 'fields', { name: m('code.label', 'fields') }));
			hasEmptyFields = true;
		} else {
			isValidOtp = otp.length === 6;
		}

		await hasEmptyFieldsError(hasEmptyFields);

		if (!isValidOtp) {
			setOtpError(m('validation.error.invalidNamed', 'fields', { name: m('code.label', 'fields') }));
			await addErrorMessage(
				FormErrorCode.API_OTHER,
				m('validation.error.otp.invalid', 'fields')
			);
		}

		const isValid = !hasEmptyFields && isValidOtp;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postVerifyOtp(otp, storeAccessToken, onValid, onInvalid);
		} else {
			await onInvalid();
		}
	};

	const onValid = (data) => {
		continueValid();
		dispatch(setVcJwtToken(data.vcJwtToken));
		if (isStaticSubType() && data.vcJwtToken) {
			if (
				data.vcJwtToken &&
				!data.webIdFinished &&
				!data.postIdentFinished
			) {
				console.log(currentFlow + ': Web ID not finished');
				if (data.postIdentStarted) {
					console.log('PostIdent started');
					goToPage(currentFlow + C.ROUTES.DIGITAL_POSTIDENT);
				} else {
					goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
				}
			} else if (
				data.vcJwtToken &&
				(data.webIdFinished || data.postIdentFinished)
			) {
				console.log(currentFlow + ': Web ID finished');
				goToPage(currentFlow + C.ROUTES.WEB_ID_DONE);
			}
		} else if (data.vcJwtToken && data.secondBorrower) {
			console.log(currentFlow + ': Has second applicant');
			goToPage(currentFlow + C.ROUTES.FINAL_STEPS_TWO_BORROWERS);
		} else if (
			data.vcJwtToken &&
			!data.capsUploadDone &&
			!data.webIdFinished
		) {
			console.log(currentFlow + ': Documents needed, Web ID not finished');
			if (isCreditSubType()) {
				goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS);
			} else {
				if (
					isCreditCardSubType(null, [C.FRONTEND.VISACARD])
					&& data.bcoResult?.approvedBestCardLimit
					&& data.requestedBestCardLimit
					&& parseInt(data.bcoResult?.approvedBestCardLimit) !== parseInt(data.requestedBestCardLimit)
				) {
					goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN_LIMIT);
				} else if (isCreditCardSubType(null, [C.FRONTEND.VISACARD]) && data.requestFromCrossSelling) {
					goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS);
				} else {
					goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
				}
			}
		} else if (
			data.vcJwtToken &&
			data.capsUploadDone &&
			!data.webIdFinished
		) {
			console.log(currentFlow + ': No documents needed, Web ID not finished');
			goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
		} else if (
			data.vcJwtToken &&
			!data.capsUploadDone &&
			data.webIdFinished
		) {
			console.log(currentFlow + ': Documents needed, Web ID finished');
			goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS + '/reminder');
		} else if (
			data.vcJwtToken &&
			data.capsUploadDone &&
			data.webIdFinished
		) {
			console.log(currentFlow + ': Web ID finished');
			goToPage(currentFlow + C.ROUTES.WEB_ID_DONE);
		} else {
			console.log(currentFlow + ': Default');
			goToPage(currentFlow + C.ROUTES.NATIONALITY);
		}
	};

	const onInvalid = (statusCode, blockedUntil) => {
		continueInvalid();
		if (statusCode === 423) {
			if (blockedUntil) {
				const datetime = moment(blockedUntil);
				const blockedUntilMessage = m(
					'code.validation.tooManyAttempts',
					'fields',
					{
						date: datetime.format('DD.MM.YYYY'),
						time: datetime.format('HH:mm')
					}
				);
				addErrorMessage(FormErrorCode.API_OTHER, blockedUntilMessage);
			} else {
				setOtpError(m('validation.error.invalidNamed', 'fields', { name: m('code.label', 'fields') }));
				addErrorMessage(FormErrorCode.API_OTHER, m('validation.error.otp.invalid', 'fields'));
			}
		} else {
			setOtpError(m('validation.error.invalidNamed', 'fields', { name: m('code.label', 'fields') }));
			addErrorMessage(FormErrorCode.API_OTHER, m('validation.error.otp.invalid', 'fields'));
		}
	};

	const onResendOtp = async () => {
		if (!resendCodeDisabled) {
			await postGenerateOtp(() => {
				addSuccessMessage(FormSuccessCode.OTHER, m('code.success.newCode', 'fields'));
				setResendCodeDisabled(true);
				setTimeout(() => {
					setResendCodeDisabled(false);
				}, resendTimeout);
			});
		}
	};

	const autofill = () => {
		setOtp('123456');
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.mobileNumberConfirmation.title', 'global')}</Title>
			<Text size="l" mb>
				{storeRelogin ? (
					<>
						{storeEncryptedMobileNumber ? (
							<span>
								{m(
									'pages.mobileNumberConfirmation.subtitleEncryptedNumber',
									'global',
									{ number: storeEncryptedMobileNumber }
								)}
							</span>
						) : (
							<span>{m('pages.mobileNumberConfirmation.subtitleNoNumber', 'global')}</span>
						)}
					</>
				) : (
					<>
						{storeCountryCode && storeMobileNumber ? (
							<span>
								{m(
									'pages.mobileNumberConfirmation.subtitle',
									'global',
									{ number: `+${storeCountryCode} ${storeMobileNumber}` }
								)}
							</span>
						) : (
							<span>{m('pages.mobileNumberConfirmation.subtitleNoNumber', 'global')}</span>
						)}
					</>
				)}
			</Text>
			<Divider noLine />
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={2}
				onPrevButton={
					registration
						? () => { goToPage(currentFlow + C.ROUTES.MOBILE_NUMBER); }
						: null
				}
			>
				<TextInput
					value={otp}
					setValue={setOtp}
					label={m('code.enter', 'fields')}
					hasError={!!otpError}
					message={otpError}
					maxLength={6}
					regex={NUMBER_INPUT}
					tabindex={1}
					autoComplete={Autocomplete.ONE_TIME_CODE}
					type="pin"
					preventFocusMark
					testId="passcode"
				/>
				<Button
					onClick={onResendOtp}
					disabled={resendCodeDisabled}
					prependIcon={<SvgReload />}
					buttonStyle="link"
				>
					{m('code.resend', 'fields')}
				</Button>
			</Form>
		</>
	);
};
export default Otp;
