import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import C from 'constants/Constants';
import { NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';

import { Row, Col } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { TextInput, DropdownInput } from 'components/Atoms/Form';
import SvgEuro from 'components/Atoms/SVG/Icons/SvgEuro';
import SvgIllustrationDataCollection from 'components/Atoms/SVG/Illustrations/SvgIllustrationDataCollection';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import {
	setAdditionalIncome,
	setExpenditure,
	setIncome,
	setOtherExpenses,
	setTypeOfLiving
} from 'store/FinancialStatus/actions';

import styles from './DigitalAccountCheckFailure.module.scss';

const DigitalAccountCheckFailure = () => {
	const { getCurrentFlow, isCreditCardSubType } = useUtils();
	const { goToPage } = usePageUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { continueInvalid, continueValid } = useTrackingUtils();
	const { getTypeOfLivingItems } = useItemsUtils();

	const currentFlow = getCurrentFlow();

	const params = useParams();
	const dispatch = useDispatch();
	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));
	const storeExpenditure = useSelector((state => state.financialStatus.expenditure));
	const storeOtherExpenses = useSelector((state => state.financialStatus.otherExpenses));
	const storeTypeOfLiving = useSelector((state => state.financialStatus.typeOfLiving));

	const [incomeError, setIncomeError] = useState(null);
	const [additionalIncomeError, setAdditionalIncomeError] = useState(null);
	const [expenditureError, setExpenditureError] = useState(null);
	const [otherExpensesError, setOtherExpensesError] = useState(null);
	const [typeOfLivingError, setTypeOfLivingError] = useState(null);

	const typeOfLivingItems = getTypeOfLivingItems();

	const [isReminder, setIsReminder] = useState(false);

	useEffect(() => {
		if (params.isReminder && params.isReminder === 'reminder') {
			setIsReminder(true);
		}
	}, []);

	const clearErrors = () => {
		setIncomeError(null);
		setAdditionalIncomeError(null);
		setExpenditureError(null);
		setOtherExpensesError(null);
		setTypeOfLivingError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (!isReminder) {
			if (isEmpty(storeIncome, true)) {
				setIncomeError(m('validation.error.requiredNamed', 'fields', { name: m('income.labelWithoutNet', 'fields') }));
				hasEmptyFields = true;
			}
			if (isEmpty(storeExpenditure, true)) {
				setExpenditureError(m('validation.error.requiredNamed', 'fields', { name: m('expenditure.label', 'fields') }));
				hasEmptyFields = true;
			}
			if (isEmpty(storeTypeOfLiving)) {
				setTypeOfLivingError(m('validation.error.requiredNamed', 'fields', { name: m('typeOfLiving.label', 'fields') }));
				hasEmptyFields = true;
			}
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			continueValid();
			if (isCreditCardSubType()) {
				goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
			} else {
				goToPage(currentFlow + (isReminder ? C.ROUTES.UPLOAD_DOCUMENTS : C.ROUTES.EXTERNAL_CREDIT));
			}
		} else {
			continueInvalid();
		}
	};

	const autofill = () => {
		dispatch(setIncome(3000));
		dispatch(setExpenditure(500));
		dispatch(setTypeOfLiving('MIETWOHNUNG'));
	};

	return (
		<>
			{isReminder ? (
				<Row>
					<Col xs={12} md={6}>
						<div className={styles.iconGroup}>
							<SvgIllustrationDataCollection />
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className={styles.textGroup}>
							<Title mb>{m('pages.digitalAccountCheckFailure.title', 'global', null, true)}</Title>
							<Text size="l">{m('pages.digitalAccountCheckFailure.subtitleReminder', 'global')}</Text>
						</div>
					</Col>
				</Row>
			) : (
				<>
					<Autofill autofill={autofill} />
					<Title mb>{m('pages.digitalAccountCheckFailure.title', 'global')}</Title>
					<Text size="l" mb>{m('pages.digitalAccountCheckFailure.subtitle', 'global')}</Text>
				</>
			)}
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={6}
			>
				{!isReminder && (
					<Row>
						<Col xs={12} sm={6}>
							<TextInput
								value={storeIncome || storeIncome === 0 ? storeIncome + '' : null}
								setValue={(value) => { dispatch(setIncome(value)); }}
								label={m('income.labelWithoutNet', 'fields')}
								hasError={!!incomeError}
								message={incomeError ?? m('income.placeholder', 'fields')}
								regex={NUMBER_INPUT}
								tooltip={
									<>
										<strong>{m('income.labelWithoutNet', 'fields')}</strong>
										<br />
										{m('income.tooltip', 'fields')}
									</>
								}
								suffix={<SvgEuro />}
								tabindex={1}
								testId="income"
							/>
						</Col>
						<Col xs={12} sm={6}>
							<TextInput
								value={storeAdditionalIncome || storeAdditionalIncome === 0 ? storeAdditionalIncome + '' : null}
								setValue={(value) => { dispatch(setAdditionalIncome(value)); }}
								label={m('additionalIncome.monthlyNet', 'fields')}
								hasError={!!additionalIncomeError}
								message={additionalIncomeError ?? m('additionalIncome.placeholder', 'fields')}
								regex={NUMBER_INPUT}
								tooltip={
									<>
										<strong>{m('additionalIncome.label', 'fields')}</strong>
										<br />
										{m('additionalIncome.tooltip', 'fields')}
									</>
								}
								suffix={<SvgEuro />}
								tabindex={2}
								testId="additional-income"
							/>
						</Col>
						<Col xs={12}>
							<DropdownInput
								value={storeTypeOfLiving}
								setValue={(value) => { dispatch(setTypeOfLiving(value)); }}
								items={typeOfLivingItems}
								smallItemBox
								label={m('typeOfLiving.label', 'fields')}
								hasError={!!typeOfLivingError}
								message={typeOfLivingError}
								tabindex={3}
								testId="type-of-living"
							/>
						</Col>
						<Col xs={12} sm={6}>
							<TextInput
								value={storeExpenditure || storeExpenditure === 0 ? storeExpenditure + '' : null}
								setValue={(value) => { dispatch(setExpenditure(value)); }}
								label={
									storeHasSecondApplicant
										? m('expenditure.monthlyBoth', 'fields')
										: m(`expenditure.monthly${storeTypeOfLiving === 'MIETWOHNUNG' ? 'Rent' : ''}`, 'fields')
								}
								hasError={!!expenditureError}
								message={expenditureError ?? m('expenditure.placeholder', 'fields')}
								regex={NUMBER_INPUT}
								suffix={<SvgEuro />}
								tabindex={4}
								testId="expenditure"
							/>
						</Col>
						<Col xs={12} sm={6}>
							<TextInput
								value={storeOtherExpenses || storeOtherExpenses === 0 ? storeOtherExpenses + '' : null}
								setValue={(value) => { dispatch(setOtherExpenses(value)); }}
								label={storeHasSecondApplicant ? m('otherExpenses.monthlyBoth', 'fields') : m('otherExpenses.monthly', 'fields')}
								hasError={!!otherExpensesError}
								message={otherExpensesError ?? m('otherExpenses.placeholder', 'fields')}
								regex={NUMBER_INPUT}
								suffix={<SvgEuro />}
								tabindex={5}
								testId="other-expenses"
							/>
						</Col>
					</Row>
				)}
			</Form>
		</>
	);
};
export default DigitalAccountCheckFailure;
