import React, { useRef, useState } from 'react';
import C from 'constants/Constants';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import {
	CHAR_NUMBER_SPACE_DASH_DOT_INPUT,
	CHAR_SPACE_DASH_DOT_INPUT,
	CHAR_SPACE_DASH_INPUT,
	EMAIL_INPUT,
	NUMBER_INPUT
} from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useItemsUtils } from 'utils/items/items';
import { useApplicationApi } from 'api/application';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Link, Text, Title } from 'components/Atoms/Text';
import { TextInput, DropdownInput } from 'components/Atoms/Form';
import Modal from 'components/Atoms/Modal/Modal';
import SchufaModalContent from 'components/Atoms/Partials/Content/SchufaModalContent/SchufaModalContent';
import Autofill from 'components/Dev/Autofill/Autofill';
import Divider from 'components/Atoms/Divider/Divider';
import SvgEuro from 'components/Atoms/SVG/Icons/SvgEuro';

import { useDispatch, useSelector } from 'react-redux';
import { setSalutation, setFirstName, setLastName } from 'store/Name/actions';
import { setBirthdate } from 'store/Birthdate/actions';
import { setMaritalStatus } from 'store/MaritalStatus/actions';
import { setStreet, setNumber, setPostcode, setCity } from 'store/Address/actions';
import { setOccupation } from 'store/Occupation/actions';
import { setIncome, setAdditionalIncome } from 'store/FinancialStatus/actions';
import { setCountryCode, setMobileNumber } from 'store/MobileNumber/actions';
import { setEmail } from 'store/Email/actions';
import DateInput from 'components/Atoms/Form/DateInput/DateInput';
import moment from 'moment/moment';

const CustomerData = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const {
		isEmpty,
		hasEmptyFieldsError,
		focusFirstErrorInput,
		hasCompanyInNameError,
		hasAllUpperOrLowercaseError,
		isBirthdateValid,
		// isMobileNumberValid,
		hasEmailError,
		addErrorMessage
	} = useValidationUtils();
	const { getSalutationDropdownItems, getMaritalStatusItemsFull, getOccupationItemsFull, getCountryCodeDropdownItems } = useItemsUtils();
	const { postApplicationShort } = useApplicationApi();

	const salutationItems = getSalutationDropdownItems();
	const maritalStatusItems = getMaritalStatusItemsFull();
	const occupationItems = getOccupationItemsFull();
	const countryCodeItems = getCountryCodeDropdownItems();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();

	const storeSalutation = useSelector((state => state.name.salutation));
	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));
	const storeBirthdate = useSelector((state => state.birthdate.birthdate));
	const storeMaritalStatus = useSelector((state => state.maritalStatus.maritalStatus));
	const storeStreet = useSelector((state => state.address.street));
	const storeStreetNumber = useSelector((state => state.address.number));
	const storePostcode = useSelector((state => state.address.postcode));
	const storeCity = useSelector((state => state.address.city));
	const storeOccupation = useSelector((state => state.occupation.occupation));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeAdditionalIncome = useSelector((state => state.financialStatus.additionalIncome));
	const storeCountryCode = useSelector((state => state.mobileNumber.countryCode));
	const storeMobileNumber = useSelector((state => state.mobileNumber.mobileNumber));
	const storeEmail = useSelector((state => state.email.email));

	const [salutationError, setSalutationError] = useState(null);
	const [firstNameError, setFirstNameError] = useState(null);
	const [lastNameError, setLastNameError] = useState(null);
	const [birthdateError, setBirthdateError] = useState(null);
	const [maritalStatusError, setMaritalStatusError] = useState(null);
	const [streetError, setStreetError] = useState(null);
	const [streetNumberError, setStreetNumberError] = useState(null);
	const [postcodeError, setPostcodeError] = useState(null);
	const [cityError, setCityError] = useState(null);
	const [occupationError, setOccupationError] = useState(null);
	const [incomeError, setIncomeError] = useState(null);
	const [additionalIncomeError, setAdditionalIncomeError] = useState(null);
	const [countryCodeError, setCountryCodeError] = useState(null);
	const [mobileNumberError, setMobileNumberError] = useState(null);
	const [emailError, setEmailError] = useState(null);

	const [popUpIsOpen, setPopUpIsOpen] = useState(false);

	const cityRef = useRef(null);

	const clearErrors = () => {
		setSalutationError(null);
		setFirstNameError(null);
		setLastNameError(null);
		setBirthdateError(null);
		setMaritalStatusError(null);
		setStreetError(null);
		setStreetNumberError(null);
		setPostcodeError(null);
		setCityError(null);
		setOccupationError(null);
		setIncomeError(null);
		setAdditionalIncomeError(null);
		setCountryCodeError(null);
		setMobileNumberError(null);
		setEmailError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let hasCompanyError = false;
		let hasUpperLowercaseError = false;
		let isValidBirthdate = true;
		let isValidPostcode = true;
		let isValidCity = true;
		let isValidStreet = true;
		let isValidMobileNumber = true;
		let isValidEmail = true;

		if (isEmpty(storeSalutation)) {
			setSalutationError(m('validation.error.requiredNamed', 'fields', { name: m('salutation.label', 'fields') }));
			hasEmptyFields = true;
		}
		if (isEmpty(storeFirstName)) {
			setFirstNameError(m('validation.error.requiredNamed', 'fields', { name: m('firstName.label', 'fields') }));
			hasEmptyFields = true;
		} else if (hasAllUpperOrLowercaseError(storeFirstName)) {
			setFirstNameError(m('validation.error.name.upperLowerCaseFirstName', 'fields'));
			hasUpperLowercaseError = true;
		}
		if (isEmpty(storeLastName)) {
			setLastNameError(m('validation.error.requiredNamed', 'fields', { name: m('lastName.label', 'fields') }));
			hasEmptyFields = true;
		} else if (hasCompanyInNameError(storeLastName)) {
			setLastNameError(m('validation.error.name.isCompany', 'fields'));
			hasCompanyError = true;
		} else if (hasAllUpperOrLowercaseError(storeLastName)) {
			setLastNameError(m('validation.error.name.upperLowerCaseLastName', 'fields'));
			hasUpperLowercaseError = true;
		}
		if (isEmpty(storeBirthdate)) {
			setBirthdateError(m('validation.error.requiredNamed', 'fields', { name: m('birthdate.label', 'fields') }));
			hasEmptyFields = true;
		} else {
			isValidBirthdate = isBirthdateValid(storeBirthdate, setBirthdateError);
		}
		if (isEmpty(storePostcode)) {
			setPostcodeError(m('validation.error.requiredNamed', 'fields', { name: m('postcode.label', 'fields') }));
			hasEmptyFields = true;
		} else if (storePostcode.length !== 5) {
			setPostcodeError(m('validation.error.postcode.length', 'fields'));
			isValidPostcode = false;
		}
		if (isEmpty(storeCity)) {
			setCityError(m('validation.error.requiredNamed', 'fields', { name: m('city.label', 'fields') }));
			hasEmptyFields = true;
		} else if (storeCity.length < 2) {
			setCityError(m('validation.error.city.length', 'fields'));
			isValidCity = false;
		}
		if (isEmpty(storeStreet)) {
			setStreetError(m('validation.error.requiredNamed', 'fields', { name: m('street.label', 'fields') }));
			hasEmptyFields = true;
		} else if (storeStreet.length < 3) {
			setStreetError(m('validation.error.street.length', 'fields'));
			isValidStreet = false;
		}
		if (isEmpty(storeStreetNumber)) {
			setStreetNumberError(m('validation.error.requiredNamed', 'fields', { name: m('houseNumber.short', 'fields') }));
			hasEmptyFields = true;
		}
		if (isEmpty(storeCountryCode)) {
			setCountryCodeError(m('validation.error.requiredNamed', 'fields', { name: m('countryCode.label', 'fields') }));
			hasEmptyFields = true;
		}
		if (isEmpty(storeMobileNumber)) {
			setMobileNumberError(m('validation.error.requiredNamed', 'fields', { name: m('mobileNumber.label', 'fields') }));
			hasEmptyFields = true;
		}
		/*if (!isEmpty(storeCountryCode) && !isEmpty(storeMobileNumber)) {
			isValidMobileNumber = isMobileNumberValid(storeCountryCode, storeMobileNumber, setMobileNumberError);
		}*/
		if (isEmpty(storeEmail)) {
			setEmailError(m('validation.error.requiredNamed', 'fields', { name: m('email.label', 'fields') }));
			hasEmptyFields = true;
		} else {
			isValidEmail = !hasEmailError(storeEmail);
			setEmailError(!isValidEmail ? m('validation.error.email.invalid', 'fields') : null);
		}
		await hasEmptyFieldsError(hasEmptyFields);

		if (
			!hasEmptyFields &&
			!hasUpperLowercaseError &&
			isValidBirthdate &&
			isValidEmail &&
			(!isValidPostcode || !isValidCity || !isValidStreet)
		) {
			await addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
		}

		const isValid = !hasEmptyFields &&
			!hasCompanyError &&
			!hasUpperLowercaseError &&
			!hasCompanyError &&
			isValidBirthdate &&
			isValidPostcode &&
			isValidCity &&
			isValidStreet &&
			isValidMobileNumber &&
			isValidEmail;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = async () => {
		await postApplicationShort(
			() => {
				continueValid();
				goToPage(currentFlow + C.ROUTES.THANKYOU);
			},
			() => {
				continueValid();
				goToPage(currentFlow + C.ROUTES.THANKYOU);
			}
		);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setSalutation('MALE'));
		dispatch(setFirstName('Max'));
		dispatch(setLastName('Mustermann'));
		dispatch(setBirthdate('1990-01-31'));
		dispatch(setMaritalStatus('SINGLE'));
		dispatch(setStreet('Madrider Straße'));
		dispatch(setNumber('1'));
		dispatch(setPostcode('41069'));
		dispatch(setCity('Mönchengladbach'));
		dispatch(setOccupation('EMPLOYED'));
		dispatch(setIncome('5000'));
		dispatch(setAdditionalIncome('500'));
		dispatch(setCountryCode('49'));
		dispatch(setMobileNumber('163123456'));
		dispatch(setEmail('test@santander.de'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Form
				submitText={m('shortApplication.submitText', currentFlow)}
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={16}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.INDEX); }}
			>
				<Row>
					<Col xs={12}>
						<Title size="m" mb>
							{m('personalData', 'global')}
						</Title>
					</Col>

					<Col xs={12} sm={4}>
						<DropdownInput
							value={storeSalutation}
							setValue={(value) => { dispatch(setSalutation(value)); }}
							items={salutationItems}
							label={m('salutation.label', 'fields')}
							hasError={!!salutationError}
							message={salutationError}
							tabindex={1}
							testId="salutation"
						/>
					</Col>
					<Col xs={12} sm={4}>
						<TextInput
							value={storeFirstName}
							setValue={(value) => { dispatch(setFirstName(value)); }}
							label={m('firstName.label', 'fields')}
							maxLength={50}
							hasError={!!firstNameError}
							message={firstNameError}
							autoComplete={Autocomplete.GIVEN_NAME}
							regex={CHAR_SPACE_DASH_INPUT}
							tabindex={2}
							testId="first-name"
						/>
					</Col>
					<Col xs={12} sm={4}>
						<TextInput
							value={storeLastName}
							setValue={(value) => { dispatch(setLastName(value)); }}
							label={m('lastName.label', 'fields')}
							maxLength={50}
							hasError={!!lastNameError}
							message={lastNameError}
							autoComplete={Autocomplete.FAMILY_NAME}
							regex={CHAR_SPACE_DASH_INPUT}
							tabindex={3}
							testId="last-name"
						/>
					</Col>

					<Col xs={12}>
						<DateInput
							value={storeBirthdate}
							setValue={(value) => { dispatch(setBirthdate(value)); }}
							label={m('birthdate.label', 'fields')}
							max={moment().format('YYYY-MM-DD')}
							min={moment('1900').format('YYYY-MM-DD')}
							hasError={!!birthdateError}
							message={birthdateError}
							tabindex={4}
							testId="birthdate"
						/>
					</Col>

					<Col xs={12}>
						<DropdownInput
							value={storeMaritalStatus}
							setValue={(value) => { dispatch(setMaritalStatus(value)); }}
							items={maritalStatusItems}
							label={m('maritalStatus.label', 'fields')}
							hasError={!!maritalStatusError}
							message={maritalStatusError}
							tabindex={5}
							testId="marital-status"
						/>
					</Col>

					<Col xs={12}>
						<Divider noMarginBottom noLine />
						<Title size="m" mb>
							{m('address', 'global')}
						</Title>
					</Col>

					<Col xs={8}>
						<TextInput
							value={storeStreet}
							setValue={(value) => { dispatch(setStreet(value)); }}
							label={m('street.label', 'fields')}
							hasError={!!streetError}
							message={streetError}
							maxLength={50}
							regex={CHAR_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LINE_1}
							tabindex={6}
							testId="street"
						/>
					</Col>
					<Col xs={4}>
						<TextInput
							value={storeStreetNumber}
							setValue={(value) => { dispatch(setNumber(value)); }}
							label={m('houseNumber.short', 'fields')}
							hasError={!!streetNumberError}
							message={streetNumberError}
							maxLength={8}
							regex={CHAR_NUMBER_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LINE_2}
							tabindex={7}
							testId="house-number"
						/>
					</Col>
					<Col xs={4}>
						<TextInput
							value={storePostcode}
							setValue={(value) => { dispatch(setPostcode(value)); }}
							label={m('postcode.label', 'fields')}
							hasError={!!postcodeError}
							message={postcodeError}
							maxLength={5}
							regex={NUMBER_INPUT}
							autoComplete={Autocomplete.POSTAL_CODE}
							jumpToNextOnMaxLength
							tabindex={8}
							testId="postal-code"
						/>
					</Col>
					<Col xs={8}>
						<TextInput
							ref={cityRef}
							value={storeCity}
							setValue={(value) => { dispatch(setCity(value)); }}
							label={m('city.label', 'fields')}
							hasError={!!cityError}
							message={cityError}
							maxLength={50}
							regex={CHAR_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LEVEL_2}
							tabindex={9}
							testId="city"
						/>
					</Col>

					<Col xs={12}>
						<Divider noMarginBottom noLine />
						<Title size="m" mb>
							{m('occupation', 'global')}
						</Title>
					</Col>

					<Col xs={12}>
						<DropdownInput
							value={storeOccupation}
							setValue={(value) => { dispatch(setOccupation(value)); }}
							items={occupationItems}
							label={m('occupation.label', 'fields')}
							hasError={!!occupationError}
							message={occupationError}
							tabindex={10}
							testId="occupation"
						/>
					</Col>

					<Col xs={12} sm={6}>
						<TextInput
							value={storeIncome || storeIncome === 0 ? storeIncome + '' : null}
							setValue={(value) => { dispatch(setIncome(value)); }}
							label={m('income.monthlyNet', 'fields')}
							hasError={!!incomeError}
							message={incomeError ?? m('income.placeholder', 'fields')}
							regex={NUMBER_INPUT}
							suffix={<SvgEuro />}
							tabindex={11}
							testId="income"
						/>
					</Col>
					<Col xs={12} sm={6}>
						<TextInput
							value={storeAdditionalIncome || storeAdditionalIncome === 0 ? storeAdditionalIncome + '' : null}
							setValue={(value) => { dispatch(setAdditionalIncome(value)); }}
							label={m('additionalIncome.monthlyNet', 'fields')}
							hasError={!!additionalIncomeError}
							message={additionalIncomeError ?? m('additionalIncome.placeholder', 'fields')}
							regex={NUMBER_INPUT}
							suffix={<SvgEuro />}
							tabindex={12}
							testId="additional-income"
						/>
					</Col>

					<Col xs={12}>
						<Divider noMarginBottom noLine />
						<Title size="m" mb>
							{m('contactDetails', 'global')}
						</Title>
					</Col>

					<Col xs={12} sm={4}>
						<DropdownInput
							value={storeCountryCode}
							setValue={(value) => { dispatch(setCountryCode(value)); }}
							items={countryCodeItems}
							label={m('countryCode.label', 'fields')}
							hasError={!!countryCodeError}
							message={countryCodeError}
							tabindex={13}
							testId="country-code"
						/>
					</Col>
					<Col xs={12} sm={8}>
						<TextInput
							value={storeMobileNumber}
							setValue={(value) => { dispatch(setMobileNumber(value)); }}
							label={m('shortApplication.phoneLabel', currentFlow)}
							hasError={!!mobileNumberError}
							message={mobileNumberError ?? (storeCountryCode + '' === '49' ? 'z.B. "17012345678"' : '')}
							maxLength={13}
							regex={NUMBER_INPUT}
							autoComplete={Autocomplete.TEL_NATIONAL}
							disabled={!storeCountryCode}
							tabindex={14}
							testId="mobile-phone"
						/>
					</Col>
					<Col xs={12}>
						<TextInput
							value={storeEmail}
							setValue={(value) => { dispatch(setEmail(value)); }}
							label={m('email.label', 'fields')}
							hasError={!!emailError}
							message={emailError}
							maxLength={50}
							regex={EMAIL_INPUT}
							autoComplete={Autocomplete.EMAIL}
							tabindex={15}
							testId="email"
						/>
					</Col>

				</Row>

				<Divider />
				<Text size="m" mb>
					{m('shortApplication.hint.text1', currentFlow, null, true)}{' '}
					<Link onClick={() => { setPopUpIsOpen(true); }}>{m('acceptSchufa.linkText', 'fields')}</Link>{' '}
					{m('shortApplication.hint.text2', currentFlow)}
				</Text>
				<Modal
					isOpen={popUpIsOpen}
					onClose={() => {
						setPopUpIsOpen(false);
					}}
				>
					<SchufaModalContent />
				</Modal>
			</Form>
		</>
	);
};
export default CustomerData;
