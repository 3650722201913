module.exports = {
	error: {
		404: '404 - Die Seite wurde nicht gefunden.',
		connection:
			'Verbindungsfehler, bitte versuchen Sie es später noch einmal.<br/>',
		mobileNotVerified:
			'Bitte klicken Sie auf den Registrierungslink in der E-Mail um fortzufahren.<br/>',
		missingData: 'Es fehlen Daten, um eine Verbindung herzustellen.<br/>'
	},
	validation: {
		error: {
			connection: 'Verbindungsfehler, bitte versuchen Sie es später noch einmal.',
			missingData: 'Es fehlen Daten, um eine Verbindung herzustellen.',
			emptyFields: 'Es gibt nicht ausgefüllte Pflichtfelder.',
			hasInvalidFields: 'Es gibt fehlerhaft ausgefüllte Felder.',
			required: 'Dieses Feld ist erforderlich.',
			requiredNamed: 'Das Feld "{name}" ist erforderlich.',
			invalid: 'Die Eingabe ist nicht valide.',
			invalidNamed: 'Die Eingabe für das Feld "{name}" ist nicht valide.',
			name: {
				upperLowerCaseFirstName: 'Der Vorname muss mit einem Großbuchstaben beginnen.',
				upperLowerCaseLastName: 'Der Nachname muss mit einem Großbuchstaben beginnen.',
				isCompany: 'Der Nachname kann kein Firmenname sein.'
			},
			birthdate: {
				invalid: 'Bitte geben Sie ein valides Geburtsdatum im Format TT.MM.JJJJ an.',
				day: {
					outOfRange: 'Die Eingabe für den Tag muss zwischen 1 und 31 liegen.'
				},
				month: {
					outOfRange: 'Die Eingabe für den Monat muss zwischen 1 und 12 liegen.'
				},
				year: {
					invalid: 'Die Eingabe für das Jahr ist inkorrekt.'
				},
				notOver18: 'Bitte beachten Sie, dass Sie den Antrag nur fortführen können, wenn Sie volljährig sind.',
				notYearLessThan1900: 'Die Eingabe für das Jahr muss über 1900 liegen.'
			},
			email: {
				invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
			},
			registeredButNotVerified: 'Sie haben sich bereits registriert, aber die Registrierung ist noch nicht abgeschlossen. Bitte klicken Sie auf den Registrierungslink in der E-Mail um fortzufahren.',
			mobileNumber: {
				tooShort: 'Die Mobilfunknummer darf nicht weniger als 5 Zeichen enthalten.',
				noGermanPrefix: 'Die eingegebene Vorwahl der Mobilfunknummer entspricht nicht dem gültigen Format.'
			},
			otp: {
				invalid: 'Der eingegebene Code ist falsch.',
				tooManyAttempts: 'Auf Grund von Fehleingaben des Bestätigungscodes ist Ihre Anmeldung bis zum {date} um {time} Uhr deaktiviert.'
			},
			postcode: {
				length: 'Die PLZ muss 5 Ziffern haben.'
			},
			city: {
				length: 'Der Ort muss min. 2 Zeichen haben.'
			},
			street: {
				length: 'Die Straße muss min. 3 Zeichen haben.'
			},
			occupation: {
				since: {
					startInTheFuture: 'Der Beschäftigungsstart darf nicht in der Zukunft liegen.',
					startBeforeBirthdate: 'Ihr Beschäftigungsstart passt nicht zu Ihrem Geburtsdatum.'
				},
				until: {
					startInThePast: 'Die Befristung darf nicht in der Vergangenheit liegen.',
					startBeforeOccupationStart: 'Die Befristung darf nicht vor dem Beschäftigungsstart liegen.'
				},
				noBirthdate: 'Es wurde noch kein Geburtsdatum angegeben.'
			},
			iban: {
				invalid: 'Bitte geben Sie eine gültige IBAN ein.',
				notASantanderBlz: 'Bitte geben Sie eine gültige Santander IBAN ein.',
				notGerman: 'Bitte geben Sie eine deutsche IBAN ein.',
				sameLikeExisting: 'Sie haben Ihre aktuelle Kartennummer eingegeben. Bitte geben Sie hier jedoch Ihre gültige Girokonto IBAN zur monatlichen Abrechnung ein.'
			},
			visacard: {
				notFound: 'Leider konnten wir mit diesen Daten keine Übereinstimmung finden. Bitte überprüfen Sie Ihre Eingabe.'
			},
			tin: {
				invalid: 'Bitte geben Sie eine gültige Steuer-ID ein.'
			},
			stockProficiency: {
				experiencesAreMissing: 'Bitte geben Sie weitere Infos zu Ihren Transaktionen an.'
			}
		}
	},
	yes: 'Ja',
	no: 'Nein',
	none: 'keine',
	next: {
		label: 'Weiter'
	},
	prev: {
		label: 'Zurück'
	},
	submit: {
		label: 'Bestätigen'
	},
	rate: {
		label: 'Rate',
		protect: 'Abbuchung',
		protectLong: 'Monatliche Abbuchung',
		protectFull: 'Monatliche Abbuchung inkl. Versicherung'
	},
	monthlyRate: {
		label: 'Monatliche Rate',
		labelLong: 'Monatliche Kreditrate'
	},
	amount: {
		label: 'Betrag',
		full: 'Kreditbetrag'
	},
	acceptedAmount: {
		label: 'Genehmigter Kreditbetrag'
	},
	applicationId: {
		label: 'Auftragsnummer'
	},
	runtime: {
		label: 'Laufzeit',
		labelLong: 'Kreditlaufzeit'
	},
	protectionInsurance: {
		label: 'Schutzversicherung'
	},
	wishCredit: {
		label: 'Wunschkredit'
	},
	netLoanAmount: {
		label: 'Auszahlungsbetrag'
	},
	totalAmount: {
		label: 'Gesamtkreditbetrag'
	},
	redemptionAmount: {
		label: 'Fremdverpflichtungen'
	},
	firstRate: {
		switch: '1. Rate erst in 90 Tagen',
		date: 'Erste Rate fällig am'
	},
	wishRuntime: {
		label: 'Wunschlaufzeit',
		extraCopy:
			'Wunschlaufzeit eingeschränkt wegen befristeten Arbeitsverhältnis.',
		tooltip:
			'Profitieren Sie von individuellen Laufzeiten zwischen 12 und 96 Monaten. Mit einer längeren Laufzeit sinkt Ihre monatliche Belastung und Sie bleiben finanziell flexibel.'
	},
	wishAmount: {
		label: 'Betrag'
	},
	wishAmountShortCredit: {
		label: 'Wunschbetrag'
	},
	netIncome: {
		label: 'Einkünfte',
		your: 'Ihre Einnahmen',
		yourMonthly: 'Ihre monatlichen Einnahmen',
		yourMonthlyError: 'Bitte geben Sie Ihre monatlichen Einnahmen an.'
	},
	noEntry: {
		label: 'Keine Angabe'
	},
	salutation: {
		label: 'Anrede',
		for: 'für {salutation} {firstName} {lastName}',
		items: {
			male: 'Herr',
			maleExt: 'Herrn',
			female: 'Frau'
		}
	},
	title: {
		label: 'Titel',
		items: {
			dr: 'Dr.',
			prof: 'Prof.',
			profDr: 'Prof. Dr.'
		}
	},
	firstName: {
		label: 'Vorname',
		labelMultiple: 'Vorname(n)',
		placeholder: 'gem. Ausweisdokument'
	},
	lastName: {
		label: 'Nachname',
		labelMultiple: 'Nachname(n)',
		placeholder: 'gem. Ausweisdokument'
	},
	date: {
		label: 'Datum',
		validation: {
			invalid: 'Bitte geben Sie ein gültiges Datum ein.<br/>',
			notOver18:
				'Bitte beachten Sie, dass Sie dieses Konto nur eröffnen können, wenn Sie volljährig sind.<br/>'
		}
	},
	birthdate: {
		label: 'Geburtsdatum',
		placeholder: 'TT.MM.JJJJ',
		validation: {
			invalid: 'Bitte geben Sie ein valides Geburtsdatum im Format TT.MM.JJJJ an.<br/>'
		}
	},
	day: {
		label: 'Tag',
		placeholder: 'TT',
		validation: {
			invalid: 'Die Eingabe für Tag muss zwischen 1 und {maxDays} liegen.<br/>'
		}
	},
	month: {
		label: 'Monat',
		plural: 'Monate',
		placeholder: 'MM',
		validation: {
			invalid: 'Die Eingabe für Monat muss zwischen 1 und 12 liegen.<br/>'
		}
	},
	occupiedSince: {
		month: 'Beschäftigt seit Monat',
		year: 'Beschäftigt seit Jahr'
	},
	year: {
		label: 'Jahr',
		placeholder: 'JJJJ',
		validation: {
			invalid: 'Die Eingabe für das Jahr ist inkorrekt.',
			notUnder1900: 'Die Eingabe für das Jahr muss über 1900 liegen.',
			occupationStartNotInThePast:
				'Ihr Beschäftigungsstart darf nicht in der Zukunft liegen.<br/>',
			occupationStartBeforeBirthday:
				'Ihr Beschäftigungsstart passt nicht zu Ihrem Geburtsdatum.<br/>',
			occupationLimitedStartInThePast:
				'Ihre Befristung darf nicht in der Vergangenheit liegen.<br/>',
			occupationLimitedStartBeforeOccupationStart:
				'Ihre Befristung darf nicht vor dem Beschäftigungsstart liegen.<br/>'
		}
	},
	email: {
		label: 'E-Mail',
		validation: {
			invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.<br/>',
			alreadyExists:
				'Es existiert bereits ein Account mit dieser E-Mail-Adresse.<br/>'
		}
	},
	alreadyExistsPopup: {
		text:
			'Sie sind bereits mit Ihrer E-Mail-Adresse bei uns registriert. Bitte klicken Sie auf "Weiter", um Ihren Antrag fortzuführen.',
		submit: 'Weiter'
	},
	countryCode: {
		label: 'Ländervorwahl',
		validation: {
			tooShort:
				'Die Ländervorwahl darf nicht weniger als 2 Zeichen enthalten.<br/>'
		}
	},
	phone: {
		label: 'Rufnummer',
		validation: {
			tooShort: 'Die Rufnummer darf nicht weniger als 5 Zeichen enthalten.<br/>'
		}
	},
	mobileNumber: {
		label: 'Mobilfunknummer',
		validation: {
			tooShort:
				'Die Mobilfunknummer darf nicht weniger als 5 Zeichen enthalten.<br/>',
			noGermanPrefix:
				'Die eingegebene Vorwahl der Mobilfunknummer entspricht nicht dem gültigen Format.<br/>',
			equalToFirstApplicant:
				'Die Mobilfunknummer muss sich von der Mobilfunknummer des ersten Kreditnehmers unterscheiden.<br/>'
		}
	},
	code: {
		label: 'Code',
		enter: 'Code eingeben',
		resend: 'Code erneut anfordern',
		validation: {
			invalid: 'Der eingegebene Code ist falsch.<br/>',
			tooManyAttempts:
				'Auf Grund von Fehleingaben des Bestätigungscodes ist Ihre Anmeldung bis zum {date} um {time} Uhr deaktiviert.'
		},
		success: {
			newCode: 'Wir haben Ihnen eine SMS mit einem neuen Code zugesandt.<br/>'
		}
	},
	reset: {
		label: 'Zurücksetzen'
	},
	save: {
		label: 'Speichern'
	},
	nationality: {
		label: 'Staatsangehörigkeit'
	},
	state: {
		label: 'Bundesland'
	},
	postcode: {
		label: 'PLZ',
		validation: {
			invalid: 'Bitte geben Sie eine gültige Postleitzahl ein.<br/>'
		}
	},
	city: {
		label: 'Ort',
		validation: {
			invalid: 'Bitte geben Sie einen gültigen Ortsnamen ein.<br/>'
		}
	},
	street: {
		label: 'Straße',
		validation: {
			invalid: 'Bitte geben Sie eine gültige Straße ein.<br/>'
		}
	},
	houseNumber: {
		label: 'Hausnummer',
		short: 'Nr.'
	},
	maritalStatus: {
		label: 'Familienstand',
		items: {
			single: 'ledig',
			married: 'verheiratet',
			other: 'andere',
			partnered: 'eingetragene Lebensgemeinschaft',
			separated: 'getrennt lebend',
			divorced: 'geschieden',
			widowed: 'verwitwet'
		}
	},
	numberOfChildren: {
		label: 'Anzahl im Haushalt lebende Kinder'
	},
	numberAdults: {
		label: 'Anzahl Erwachsene im Haushalt'
	},
	numberOfChildrenWithBenefit: {
		label: 'Anzahl davon kindergeldberechtigte Kinder'
	},
	occupation: {
		label: 'Beschäftigungsverhältnis',
		items: {
			employed: 'Angestellte(r)',
			pensioner: 'Rentner(in)/Pensionär(in)',
			officer: 'Beamter/Beamtin',
			freelancer: 'Selbstständige(r)',
			student: 'Student(in)',
			unemployed: 'Arbeit suchend',
			public: 'Angestellte(r) im Öffentlichen Dienst'
		},
		limited: 'Ist das Arbeitsverhältnis befristet?',
		endOfLimitation: 'Wann endet das Arbeitsverhältnis?',
		employer: 'Arbeitgeber'
	},
	occupationMonth: {
		label: 'Seit wann? (Monat)'
	},
	occupationYear: {
		label: 'Seit wann? (Jahr)'
	},
	occupationLimited: {
		label: 'Ist das Arbeitsverhältnis befristet?',
		items: {
			limited: 'Befristet',
			unlimited: 'Unbefristet'
		}
	},
	occupationLimitedMonth: {
		label: 'Bis wann? (Monat)'
	},
	occupationLimitedYear: {
		label: 'Bis wann? (Jahr)'
	},
	iban: {
		label: 'IBAN',
		placeholder: 'z.B. DE62 8888 8888 0012 3456 78',
		validation: {
			invalid: 'Bitte geben Sie eine gültige IBAN ein.<br/>',
			notASantanderBlz: 'Bitte geben Sie eine gültige Santander IBAN ein.<br/>',
			notGerman: 'Bitte geben Sie eine deutsche IBAN ein.<br/>'
		}
	},
	accountNumber: {
		label: 'Kontonummer'
	},
	referenceIban: {
		label: 'IBAN Referenzkonto'
	},
	paymentType: {
		label: 'Rückzahlung Ihrer monatlichen Kreditkartenabrechnung (jederzeit änderbar)',
		items: {
			teilzahlung: 'flexibel in Raten',
			vollzahlung: 'in einem Betrag'
		},
		hint: {
			teilzahlung: 'Sie zahlen bequem die monatlichen Teilbeträge in Höhe von 5% des ausstehenden Betrages oder mindestens 25 Euro.',
			vollzahlung: 'Sie zahlen monatlich den vollen ausstehenden Betrag und es entstehen Ihnen keine Sollzinsen.'
		}
	},
	remainingBalance: {
		label: 'Restsaldo',
		info: 'Die noch ausstehende Schuld des Kreditnehmers an den Kreditgeber, wenn der Kreditnehmer den Kredit heute vollständig zurückzahlen möchte.'
	},
	income: {
		label: 'Einkünfte (Netto)',
		labelWithoutNet: 'Einkünfte',
		monthlyNet: 'Mtl. Nettoeinkommen',
		placeholder: 'z.B. 1500',
		tooltip:
			'Wenn Sie im öffentlichen Dienst tätig sind, geben Sie Ihre monatlichen Nettoeinkünfte verringert um das Kindergeld an.'
	},
	additionalIncome: {
		label: 'Sonstige Einnahmen',
		net: 'Sonstige Einnahmen (Netto)',
		monthlyNet: 'Sonstige Nettoeinnahmen',
		placeholder: 'z.B. 500',
		tooltip:
			'Bitte geben Sie den Gesamtbetrag Ihrer sonstigen monatlichen Einnahmen an, wie z.B. Nebeneinkommen, Unterhalt, Mieteinnahmen etc. Beachten Sie dabei, dass das Kindergeld nicht dazu gehört.'
	},
	expenditure: {
		label: 'Wohnkosten',
		monthly: 'Mtl. Wohnkosten',
		monthlyRent: 'Mtl. Warmmiete inkl. Nebenkosten',
		monthlyBoth: 'Mtl. Wohnkosten beider Kreditnehmer',
		placeholder: 'z.B. 500'
	},
	otherExpenses: {
		label: 'Unterhaltszahlungen',
		monthly: 'Mtl. Unterhaltszahlungen',
		monthlyBoth: 'Mtl. Unterhaltszahlungen beider Kreditnehmer',
		placeholder: 'z.B. 350'
	},
	typeOfLiving: {
		label: 'Wohnart',
		items: {
			rentedApartment: 'Mietwohnung',
			ownerOccupiedApartment: 'Eigentumswohnung',
			ownHouse: 'Eigenes Haus',
			residentWithTheParents: 'Wohnhaft bei den Eltern'
		}
	},
	investmentAmount: {
		label: 'Betrag'
	},
	investmentRuntime: {
		label: 'Laufzeit'
	},
	investmentPaymentType: {
		label: 'Art der Zinszahlung'
	},
	requestedBestCardLimit: {
		label: 'Monatlicher Verfügungsrahmen'
	},
	newDacIbanType: {
		label: 'Art der Gehaltsangaben'
	},
	taxInformation: {
		label: 'Bitte Land auswählen'
	},
	tin: {
		label: 'Steuer-ID'
	},
	tinType: {
		label: 'Steueransässigkeit'
	},
	germanTin: {
		label: 'Deutsche Steuer-ID'
	},
	usTin: {
		label: 'US Steuer-ID',
		reason: {
			label: 'Bitte geben Sie den Steuergrund an',
			items: {
				citizenship: 'Staatsbürgerschaft',
				taxResidency: 'Steuerliche Ansässigkeit',
				both: 'Beides'
			}
		}
	},
	foreignTin: {
		label: 'Steuer-ID im Ausland'
	},
	acceptPrivacyPolicies: {
		description1: 'Im Rahmen der Produktbeantragung erheben, verarbeiten und nutzen wir Ihre personenbezogenen Daten entsprechend unserer ',
		linkText1: 'Datenschutzhinweise',
		description2: ', die ',
		linkText2: 'hier',
		description3: ' eingesehen werden können.',
		linkText: 'Datenschutzhinweise',
		validation: {
			invalid:
				'Bitte willigen Sie in die Verarbeitung Ihrer personenbezogenen Daten ein.<br/>'
		}
	},
	acceptErgo: {
		label: 'Prüfung durch die ERGO Direkt AG',
		description:
			'Ich habe zur Kenntnis genommen, dass sämtliche von mir eingereichten Daten und Unterlagen von der ERGO Direkt AG im Rahmen der Vermittlungsbetreuung geprüft werden.'
	},
	allowAdvertising: {
		description: 'Darf Santander Sie',
		descriptionCustomer: 'Darf Santander den Kunden',
		linkText: 'beraten und informieren?',
		popup: {
			headline: 'Darf Santander Sie beraten und informieren?',
			headlineCustomer: 'Darf Santander den Kunden beraten und informieren?',
			content1:
				'Ich bin einverstanden, dass mich die Santander Consumer Bank AG und die Santander Consumer Leasing GmbH per E-Mail, telefonisch, per Brief oder per sonstiger elektronischer Kommunikationsdienste (SMS o.ä.) personalisiert über neue Services und interessante Produktangebote informieren können und stimme insoweit der Verarbeitung meiner personenbezogenen Daten zu (Details hierzu siehe',
			content1Customer:
				'Der Kunde ist einverstanden, dass ihn die Santander Consumer Bank AG und die Santander Consumer Leasing GmbH per E-Mail, telefonisch, per Brief oder per sonstiger elektronischer Kommunikationsdienste (SMS o.ä.) personalisiert über neue Services und interessante Produktangebote informieren kann und stimmt insoweit der Verarbeitung seiner personenbezogenen Daten zu (Details hierzu siehe',
			content2:
				'). Dieses Einverständnis bezieht sich auf eigene und auf vermittelte Produkte und Services. Mir ist bewusst, dass ich dieses Einverständnis jederzeit <span class="underlined">widerrufen</span> kann.',
			content2Customer:
				'). Dieses Einverständnis bezieht sich auf eigene und auf vermittelte Produkte und Services. Ihm ist bewusst, dass er dieses Einverständnis jederzeit <span class="underlined">widerrufen</span> kann.',
			content3:
				'Ich habe das Recht, auch ohne Einwilligung zulässiger Werbung jederzeit zu <span class="underlined">widersprechen</span> (Details hierzu siehe',
			content3Customer:
				'Er hat das Recht, auch ohne Einwilligung zulässiger Werbung jederzeit zu <span class="underlined">widersprechen</span> (Details hierzu siehe',
			content4: 'Ziffer III.).',
			linkText: 'Santander-Werbehinweise'
		}
	},
	hasPreviousAddress: {
		description: 'Ich wohne weniger als 3 Jahre hier.'
	},
	termsAndConditions: {
		label: 'AGB und Datenschutz'
	},
	ownResponsibility: {
		label: 'Eigene Rechnung',
		description:
			'Ich handele auf eigene Rechnung (§11 Abs. 5 GwG) und bin verpflichtet, sich ergebende Änderungen der Pflichtangaben gem. §11 Abs. 6 GwG der Bank unverzüglich mitzuteilen.',
		descriptionCustomer:
			'Der Kunde handelt auf eigene Rechnung (§11 Abs. 5 GwG) und ist verpflichtet, sich ergebende Änderungen der Pflichtangaben gem. §11 Abs. 6 GwG der Bank unverzüglich mitzuteilen.'
	},
	confirmStockBasicInfo: {
		label: 'Erhalt der Basisinformationen',
		description: 'Ich bestätige den Erhalt und Download ',
		linkText:
			'der Basisinformationen über Wertpapiere und weitere Kapitalanlagen.',
		fileName: 'Basisinformationen.pdf'
	},
	paymentProtectionInsurance: {
		label: 'Ratenschutzversicherung',
		labelLong: 'Ratenschutzversicherung',
		labelExtra: 'Ratenschutzversicherung',
		description:
			'Ich willige hiermit in die Erhebung, Speicherung und Verwendung personenbezogener Daten für die Santander Protect durch Santander Consumer Bank AG sowie den Versicherer CNP Santander Insurance Europe DAC ein und bin zudem mit der Datenweitergabe an aufgabenausführende Stellen sowie selbstständige Vermittler und Rückversicherer einverstanden. Darüber hinaus bestätige ich hiermit, dass ich mit der Entbindung der Schweigepflicht einverstanden bin.',
		descriptionCustomer:
			'Der Kunde willigt hiermit in die Erhebung, Speicherung und Verwendung personenbezogener Daten für die Ratenschutzversicherung durch Santander Consumer Bank AG sowie die Versicherer CNP Santander Insurance Life DAC und/oder CNP Santander Insurance Europe DAC ein und ist zudem mit der Datenweitergabe an aufgabenausführende Stellen sowie selbstständige Vermittler und Rückversicherer einverstanden. Darüber hinaus bestätigt er hiermit, dass er mit der Entbindung der Schweigepflicht einverstanden ist.',
		linkText1: 'Datenverarbeitung und -weitergabe',
		linkText2: 'Statusbezogene Informationen Versicherungsvermittlung'
	},
	protect: {
		tarif: {
			label: 'Versicherungstarif',
			items: {
				basic: 'Protect Basic',
				standard: 'Protect Standard',
				premium: 'Protect Premium'
			}
		},
		coverage: {
			label: 'Absicherungshöhe'
		},
		monthlyRate: {
			label: 'Monatliche Absicherungsprämie'
		}
	},
	acceptSchufa: {
		label: 'Schufa',
		description1: 'Ich stimme der',
		description1Customer: 'Der Kunde stimmt der',
		linkText: 'Datenübermittlung an die SCHUFA',
		description2: 'zu.',
		popup: {
			headline: 'Datenübermittlung an die Schufa',
			content1:
				'Die Santander übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und Artikel 6 Absatz 1 Buchstabe f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der Bank oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. Der Datenaustausch mit der SCHUFA dient auch der Erfüllung gesetzlicher Pflichten zur Durchführung von Kreditwürdigkeitsprüfungen von Kunden (§ 505a des Bürgerlichen Gesetzbuches, § 18a des Kreditwesengesetzes).',
			content2: 'Der Kunde befreit Santander insoweit auch vom Bankgeheimnis.',
			content3:
				'Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen oder online unter',
			content4: 'eingesehen werden.',
			linkText: 'www.schufa.de/datenschutz'
		}
	},
	serviceHotline: {
		content1:
			'Bei Fragen steht Ihnen unser Service Team unter der Rufnummer',
		content2: 'zur Verfügung.'
	},
	openCreditDetails: {
		label: 'Details öffnen'
	},
	closeCreditDetails: {
		label: 'Details schließen'
	},
	redemptionCredit: {
		label: 'Ablösekredit',
		add: 'Ablösekredit hinzufügen'
	},
	loadingPage: {
		default: 'Wir prüfen Ihre Daten. Das kann einige Sekunden dauern.',
		webid:
			'Einen kleinen Moment… Wir verbinden mit Web-ID Solutions… Bitte halten Sie Ihren Personalausweis bereit',
		DAC:
			'Einen kleinen Moment… Wir prüfen gerade Ihre Kontodaten… Wir ermitteln erforderliche Angaben zum Einkommen und Ausgaben',
		sendingDocuments:
			'Einen kleinen Moment… Ihre Dokumente werden hochgeladen.',
		postident:
			'Einen Moment bitte… Wir verbinden gerade mit der Deutschen Post AG…',
		klarna: 'Thanks for waiting, we are connecting with Klarna.'
	},
	dropzone: {
		default: 'Datei auswählen',
		drop: 'Dokumente hier loslassen.',
		reject: 'Erlaubte Dateien:',
		addAdditionalFile: 'Weitere Datei <span class="link primary">hinzufügen</span>'
	},
	finishNow: {
		label: 'Jetzt abschließen'
	},
	goOnLater: {
		label: 'Später fertigstellen'
	},
	upload: {
		nothingToUpload:
			'Es müssen keine Dokumente mehr hochgeladen werden. Klicken Sie auf Weiter.<br/>',
		defaultError: 'Es ist ein Fehler beim Hochladen aufgetreten.<br/>',
		maxUploadSize: 'Upload nicht möglich. Die Datei ist größer als 10 MB.<br/>',
		tooManyFiles: 'Upload nicht möglich. Sie können nur 1 Datei gleichzeitig hochladen.<br/>'
	},
	tryAgain: {
		label: 'Nochmals versuchen'
	},
	interest: {
		label: 'Zinsen'
	},
	more: {
		label: 'Mehr'
	},
	moreCreditDetails: 'Mehr Kreditdetails',
	lessCreditDetails: 'Weniger Kreditdetails',
	moreInsuranceDetails: 'Mehr Versicherungsdetails',
	lessInsuranceDetails: 'Weniger Versicherungsdetails',
	creditDetails: 'Kreditdetails',
	details: 'Details',
	fixedBorrowingRate: {
		label: 'Fester Sollzinssatz (p.a)'
	},
	effectiveYearlyInterest: {
		label: 'Effektiver Jahreszins',
		description:
			'Für dieses unverbindliche Berechnungsbeispiel wird der Zinssatz von {interest} % effekt. Jahreszins verwendet. Ihr tatsächlicher Zinssatz kann davon abweichen. Bitte beachten Sie unser 2/3 Beispiel gem. §17 PAngV welches Sie auf der Santander Website beim Kreditrechner sehen.'
	},
	netAmount: {
		label: 'Auszahlungsbetrag'
	},
	nationalId: {
		label: 'National-ID'
	},
	contactForm: {
		title: 'Persönliche Daten Darlehensnehmer',
		subTitle:
			'Bitte tragen Sie in den folgenden genau die Daten ein, die in Ihrem Ausweis vermerkt sind.',
		personalData: 'Persönliche Angaben',
		birthdate: 'Geburtstag',
		contact: 'Kontakt',
		submit: 'Rückruf vereinbaren',
		phoneNumber: 'Telefonnummer',
		phoneType: 'Telefon',
		availableFrom: 'Erreichbarkeit von',
		availableTo: 'Erreichbarkeit bis',
		phoneTypes: {
			landline: 'Festnetz',
			mobile: 'Mobil'
		}
	},
	sslText: 'SSL-Verschlüsselung',
	select: 'Auswählen',
	selected: 'Ausgewählt',
	paymentToday: {
		label: 'Auszahlung heute'
	},
	hasExternalCredits: {
		label: 'Weitere Kredite ablösen'
	},
	identificationType: {
		label: 'Identifizierung'
	},
	dataRedirectionWebId: {
		label: 'Datenweiterleitung an die WebID Solutions GmbH'
	},
	termsAndConditionsWebId: {
		label: 'AGB und Datenschutz der WebID Solutions GmbH'
	},
	addressSelectionType: {
		label: 'Adressauswahl'
	},
	digitalPostidentConfirmation: {
		label: 'Datenweiterleitung'
	},
	everySecondCustomer: {
		label: 'Von jedem zweiten Kunden ausgewählt'
	},
	recommendation: {
		label: 'Empfehlung',
		uppercase: 'EMPFEHLUNG'
	},
	customerNumber: {
		label: 'Kundennummer'
	},
	creditNumberPersons: {
		label:
			'<strong>Hinweis</strong><br />Beide Antragsteller benötigen dieselbe Wohnadresse',
		onePerson: 'Eine Person',
		twoPersons: 'Zwei Personen'
	},
	firstParticipant: {
		label: 'Kreditnehmer 1'
	},
	secondApplicant: {
		label: 'Kreditnehmer 2'
	},
	box: {
		readMore: 'Mehr erfahren',
		close: 'Schließen'
	},
	stockProficiency: {
		frequencies: {
			label: 'Ø Anzahl der Transaktionen p.a. der letzten 5 Jahre',
			items: {
				zero: 'Keine',
				toFive: 'Bis 5',
				toTen: 'Bis 10',
				aboveTen: 'Über 10'
			}
		},
		volumes: {
			label: 'Ø Gegenwert pro Transaktion',
			items: {
				to5k: 'Bis 5.000 €',
				to25k: 'Bis 25.000 €',
				to50k: 'Bis 50.000 €',
				above50k: 'Über 50.000 €'
			}
		}
	},
	secureConnection: {
		title: 'Sichere Verbindung'
	}
};
