import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { useRefUtils } from 'utils/refs/refs';

import HelperText from 'components/Atoms/Form/HelperText/HelperText';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import textInputStyles from '../TextInput/TextInput.module.scss';
import styles from './RadioInput.module.scss';

const RadioInput = (props) => {
	const { focusRef } = useRefUtils();

	const [id] = useState(uuid());
	const [hasFocus, setHasFocus] = useState(false);

	const inputRef = useRef(null);

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	const align = useMemo(() => {
		switch (props.align) {
			case 'center':
				return styles.alignCenter;
			default:
				return null;
		}
	}, [props.align]);

	return (
		<fieldset className={textInputStyles.wrapper}>
			<div
				className={classNames(
					styles.items,
					hasFocus ? styles.itemsHasFocus : null,
					!props.value ? styles.noSelection : null
				)}
			>
				{props.label && (
					<div className={textInputStyles.labelWrapper}>
						<legend className={textInputStyles.label}>{props.label}</legend>
						{props.tooltip && (
							<div className={textInputStyles.tooltip}>
								<TooltipIcon text={props.tooltip} />
							</div>
						)}
					</div>
				)}
				<div className={styles.itemsWrapper}>
					{props.items?.map((item, index) => (
						<div key={id + '-' + index} className={classNames(styles.inputWrapper, align)}>
							{item.label && (
								<label
									htmlFor={id + '-' + index}
									className={classNames(
										textInputStyles.label,
										styles.label,
										'text--size--l',
										props.disabled ? styles.labelDisabled : null
									)}
								>
									{item.label}
									{item.labelExtension && (
										<span className={styles.labelExtension}>
										{item.labelExtension}
									</span>
									)}
								</label>
							)}
							<input
								ref={inputRef}
								type="radio"
								id={id + '-' + index}
								name={props.name ?? id}
								className={classNames(
									styles.input,
									props.hasError ? styles.error : null,
									props.hasError ? 'input--error' : null,
									hasFocus ? styles.hasFocus : null
								)}
								value={item.value}
								onChange={onChange}
								onFocus={() => setHasFocus(true)}
								onBlur={() => setHasFocus(false)}
								checked={props.value + '' === item.value + ''}
								disabled={props.disabled}
								tabIndex={props.tabindex}
								data-testid={props.testId + '-' + index ?? null}
								aria-live={props.hasError ? 'assertive' : null}
							/>
							<div
								className={classNames(
									styles.radio,
									props.value + '' === item.value + '' ? styles.checked : null
								)}
								onClick={
									!props.disabled
										? () => {
												focusRef(inputRef);
												props.setValue(item.value + '');
										  }
										: null
								}
							>
								<span className={styles.innerRadio} />
							</div>
						</div>
					))}
				</div>
				<HelperText
					type={props.hasError ? 'error' : 'light'}
					message={props.message}
					hasIcon
				/>
			</div>
		</fieldset>
	);
};

RadioInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	name: PropTypes.string,
	align: PropTypes.string,
	items: PropTypes.array,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	testId: PropTypes.string
};

export default RadioInput;
