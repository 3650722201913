import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from 'components/Atoms/Text';
import List from 'components/Atoms/Text/List/List';
import ToggleLink from 'components/Atoms/Partials/ToggleLink/ToggleLink';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import Divider from 'components/Atoms/Divider/Divider';

import styles from './RadioBoxItem.module.scss';
import titleStyles from '../../../Atoms/Text/Title/Title.module.scss';

const RadioBoxItem = (props) => {
	const [show, setShow] = useState(false);

	return (
		<>
			{props.title && (
				<div className={styles.titleWrapper}>
					<div className={styles.title}>
						<p className={titleStyles.s}>{props.title}</p>
						{props.tooltip && (
							<div className={styles.tooltip}>
								<TooltipIcon text={props.tooltip} />
							</div>
						)}
					</div>
					{props.icon && <div className={styles.icon}>{props.icon}</div>}
				</div>
			)}
			<div className={styles.content}>
				{props.description && (
					<div
						className={classNames(
							styles.description,
							props.hasFixedDescriptionHeight ? styles.fixedHeight : null
						)}
					>
						<Text>{props.description}</Text>
					</div>
				)}
				{props.separatedDescription && (
					<>
						<Divider noMarginTop noMarginBottom />
						<Text>{props.separatedDescription}</Text>
					</>
				)}
				{props.list && <List listStyle='checks'>{props.list}</List>}
				{props.toggleButtonText && props.toggleContent && (
					<>
						<ToggleLink active={show} setActive={setShow}>
							{props.toggleButtonText}
						</ToggleLink>
						{show && <div>{props.toggleContent}</div>}
					</>
				)}
				{props.footer && <div className={styles.footer}>{props.footer}</div>}
			</div>
		</>
	);
};

RadioBoxItem.propTypes = {
	title: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	hasFixedDescriptionHeight: PropTypes.bool,
	separatedDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	list: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	toggleButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	toggleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default RadioBoxItem;
