import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import { Link, Text, Title } from 'components/Atoms/Text';
import SvgShareLink from '../../../../Atoms/SVG/Icons/SvgShareLink';

const DepotDeactivation = () => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	return (
		<>
			<Title mb>{m('depotDeactivation.title', currentFlow)}</Title>
			<Text mb>
				{m('depotDeactivation.subtitle', currentFlow)}<br />
				<Link href="https://www.santander.de/privatkunden/wertpapiere-boerse/wertpapierdepot" tabindex={1}>
					{m('depotDeactivation.linkText', currentFlow)}
					<SvgShareLink />
				</Link>
			</Text>
		</>
	);
};
export default DepotDeactivation;
